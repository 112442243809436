import Vue from 'vue'
import VueRouter from 'vue-router'
import App from "@/views/App.vue";
import PrivacyPolicy from "@/components/PrivacyPolicy.vue";
import HomePage from "@/views/HomePage.vue";
import TermOfService from "@/components/TermOfService.vue";
import Contact from "@/components/Contact.vue";

Vue.use(VueRouter)

const routes = [

    {
        path: '/',
        name: 'App',
        component: App,
        meta: {
            title: "Mining Plan"
        },
        children: [
            {
                path: '/',
                name: 'HomePage',
                component: HomePage,
                meta: {
                    title: "Mining Plan"
                },
            },
            {
                path: '/privacy-policy',
                name: 'Privacy-Policy',
                component: PrivacyPolicy,
                meta: {
                    title: "Privacy Policy"
                }
            },
            {
                path: '/term-of-services',
                name: 'Term-Of-Service',
                component: TermOfService,
                meta:{
                    title: "Term Of Service"
                }
            },
            {
                path: '/contact',
                name: 'Contact',
                component: Contact,
                meta:{
                    title: "Contact"
                }
            },
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.VUE_APP_BASE_URL,
    routes
})

// router.beforeEach((to, from, next) => {
//     const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
//     if (nearestWithTitle) document.title = nearestWithTitle.meta.title.concat(" - Option");
//     next();
// });

export default router
