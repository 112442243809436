<template>
    <div class="homepage">
        <HowWork/>
        <About/>
        <Transaction/>
    </div>
</template>

<script>
import HowWork from "@/components/HowWork.vue";
import About from "@/components/About.vue";
import Transaction from "@/components/Transaction.vue";
export default {
    name: "HomePage",
    components: {HowWork, About,Transaction}
}
</script>
<style scoped>
.homepage {
    max-width: 100%;
    margin: 0 auto;
}
</style>