<template>
    <div class="frame-190">
        <div class="frame-142">
            <div class="frame-138">
                <div class="frame-138-text-1">About MiningPlan</div>
                <div class="frame-138-text-2">
                    At MiningPlan, we are a top cryptocurrency mining platform that provides mining capacities for a wide range of cryptocurrencies. You won't have to worry about purchasing or managing hardware devices, making it an easy and fast way for newcomers to acquire cryptocurrencies. Our goal is to make the process of acquiring cryptocurrencies accessible to everyone.
                </div>
            </div>
            <div class="frame-140">
                <div class="frame-174">
                    <div class="card-features-1">
                        <div class="feature-icon">
                            <img src="../../public/images/feature-02.png" />
                        </div>
                        <div class="frame-139">
                            <div class="frame-139-text-1">Make Profit With Zero Budget</div>
                            <div class="frame-139-text-2">Our platform assists new users who have no budget.</div>
                        </div>

                    </div>
                    <div class="card-features-2">
                        <div class="feature-icon">
                            <img src="../../public/images/feature-01.png" />
                        </div>
                        <div class="frame-139">
                            <div class="frame-139-text-1">Faucet Pay Integration</div>
                            <div class="frame-139-text-2">Withdraw profits immediately with <a style="color: white" href="https://faucetpay.io/?r=6339631">Faucet Pay</a>.</div>
                        </div>
                    </div>
                </div>
                <div class="frame-174">
                    <div class="card-features-1">
                        <div class="feature-icon">
                            <img src="../../public/images/feature-03.png" />
                        </div>
                        <div class="frame-139">
                            <div class="frame-139-text-1">Attractive Profit</div>
                            <div class="frame-139-text-2">Joining our platform will provide customers with attractive profits.
                            </div>
                        </div>
                    </div>
                    <div class="card-features-2">
                        <div class="feature-icon">
                            <img src="../../public/images/feature-04.png" />
                        </div>
                        <div class="frame-139">
                            <div class="frame-139-text-1">Affiliate</div>
                            <div class="frame-139-text-2">Share your friends to get more rewards and more profitable.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "About"
}
</script>

<style scoped>
.frame-190 {
    display: flex;
    width: 100%;
    max-width: 1200px;
    padding: 112px 0;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    border-top: 1px solid var(--back-ground-bg-secondary, #1E293B);
}

.frame-142 {
    display: flex;
    max-width: 1200px;
    justify-content: center;
    align-items: flex-start;
    gap: 40px;
}

.frame-138 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 40px;
    flex: 1 0 0;
}

.frame-138-text-1 {
    color: var(--color-text-primary, #F1F5F9);
    font-family: Urbanist;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    padding-bottom: 5px;
    /* 100% */
    background: linear-gradient(90deg, #DBE6DE 2.54%, #F9B721 113.29%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    align-self: stretch;
}

.frame-138-text-2 {
    color: var(--color-text-secondary, #94A3B8);
    font-family: Urbanist;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    /* 140% */
}

.frame-138-btn {
    display: flex;
    padding: 10px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: var(--color-text-brand, #F9BC21);
    text-decoration: none;
}

.btn-playing-demo {
    color: #000;
    font-family: Urbanist;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    text-decoration: none;
}

.frame-140 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    flex: 1 0 0;
    margin: 0 auto;
}

.frame-174 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;
}

.card-features-1 {
    display: flex;
    padding: 32px;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    border-radius: 20px;
    box-shadow: 0 1rem 2rem 0 rgba(0, 0, 0, 0.25);
    background: var(--back-ground-bg-default, #202022);
}

.feature-icon {
    display: flex;
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    border-radius: 20px;
}

.frame-139 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
}

.frame-139-text-1 {
    align-self: stretch;
    color: var(--color-text-primary, #F1F5F9);
    font-family: Urbanist;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    /* 133.333% */
}

.frame-139-text-2 {
    color: var(--color-text-read, #E2E8F0);
    /* lead-18 */
    font-family: Urbanist;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    /* 155.556% */
    align-self: stretch;
}

.card-features-2 {
    display: flex;
    padding: 32px;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    border-radius: 20px;
    box-shadow: 0 1rem 2rem 0 rgba(0, 0, 0, 0.25);
    background: var(--back-ground-bg-secondary, #1A1919);
}

@media screen and (max-width: 1440px) {
    .frame-190 {
        padding: 112px 16px;
    }
}

@media screen and (max-width: 768px) {
    .frame-142 {
        flex-direction: column;
    }

    .frame-190 {
        padding: 40px 16px;
    }

    .feature-icon img {
        width: 56px;
        height: 56px;
    }
}
</style>