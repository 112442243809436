// import EventBus from '../event-bus';
// import Cookie from "vue-cookie";

export default {
    setListLatestDeposit(state, listLatestDeposit) {
        state.listLatestDeposit = listLatestDeposit
    },
    setListLatestWithdraw(state, listLatestWithdraw) {
        state.listLatestWithdraw = listLatestWithdraw
    }
}