import Vue from 'vue'
import App from './App.vue'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
const  options={

}
// require ('dotenv').config();
Vue.use(Toast, options);
Vue.config.productionTip = false
import router from './router'
import store from "@/stores/store";
new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
