<template>
    <div :class="{
        'header': turnOnBackground,
        'disableBackground': turnOffBackground}">
        <div class="frame-2">
            <div class="logo">
                <a href="/">
                    <img src="../../public/images/logo.png" alt="">
                </a>
            </div>
            <!--            <div class="frame-6">-->
            <!--                <a class="frame-6-button" :href="registerURL">-->
            <!--                    <div class="btn-signup-text">Sign Up</div>-->
            <!--                </a>-->
            <!--            </div>-->
        </div>
        <div class="frame-191" v-if="turnOnBackground">
            <img src="../../public/images/logo-1.png" alt="">
            <div class="frame-136">
                <div class="frame-135">
                    <div class="title">

                        <div class="frame-135-text-1">
                            MINING PLAN
                        </div>

                    </div>

                    <div class="frame-135-text-2">
                        Discover the power of cryptocurrency mining with our platform
                    </div>
                    <div class="frame-135-text-3">
                        Sign Up To Get $10 USD
                    </div>
                    <div class="onboard">
                        <div @click="loginTelegram" class="frame-135-button">

                            <div class="btn-get-started-text">Let's Get Started</div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import Cookie from "vue-cookie";
import Service from "../services/service"

export default {
    name: 'HeaderSection',
    props: {
        msg: String
    },
    data() {
        return {
            loginFrom: {
                address: '',
                referrer_code_request: ''
            },
            loading: false,
            registerURL: process.env.VUE_APP_AUTHENTICATION_URL + '/register',
            signInURL: process.env.VUE_APP_AUTHENTICATION_URL,
            turnOnBackground: true,
            turnOffBackground: false,
        }
    },
    mounted() {
        if (this.$route.query.ref !== undefined) {
            Cookie.set("referral_code", this.$route.query.ref)
            this.loginFrom.referrer_code_request = this.$route.query.ref
        }
    },
    async created() {
        if (this.$route.path !== '/') {
            this.turnOnBackground = false;
            this.turnOffBackground = true
        }
    },
    methods: {
        async loginTelegram(evt){
            evt.preventDefault();
            const initData = await window.Telegram.WebApp.initData;
            const params = new URLSearchParams(window.location.search);
            const tgWebAppStartParam = params.get('tgWebAppStartParam');
            let referral_code = null;
            try {
                const tgWebAppStartParam_split = tgWebAppStartParam.split('_');
                if (tgWebAppStartParam_split[0] === 'r') {
                    referral_code = tgWebAppStartParam_split[1];
                }
            } catch (e) { }

            const resp = await Service.loginTelegram({
                init_data: initData,
                referral_code: referral_code
            })
            if (resp.data.status){
                this.$toast.success('Login Success!')
                location.replace(process.env.VUE_APP_USER_FRONTEND_URL)
                return
            }else{
                this.$toast.error('Login Failed!')
                return
            }

        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header {
    background-size: cover;
    position: relative;
    min-height: 100vh;
    flex-direction: column;
    max-width: 1920px;
    width: 100%;
    align-items: flex-start;
    justify-content: center;
    background: #202022 url('../../public/images/banners/01.png') no-repeat;
}

.frame-2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    max-width: 1200px;
    width: 100%;
    margin: 20px auto;
    background-color: #1A1919;
    border-radius: 20px;
}

.logo {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 10.162px;
}

.frame-6 {
    display: flex;
    align-items: center;
    gap: 24px;
    margin-right: 15px;
}

.frame-6-button {
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: var(--color-text-brand, #1aa053);
    text-decoration: none;
}

.frame-6-button:hover {
    cursor: pointer;
    background: var(--color-text-brand, #096046);
    text-decoration: none;
}


.btn-signup-text {
    color: #fff;
    font-family: Urbanist;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    /* 150% */
}

.frame-191 {
    display: flex;
    padding: 250px 0 150px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    align-self: center;
    justify-content: center;
    width: 1200px;
    max-width: 100%;
    margin: 0 auto;

}

.frame-136 {
    display: flex;
    width: 100%;
    position: relative;
    max-width: 700px;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    border-radius: 30px;

}

.frame-135 {
    display: flex;
    width: 100%;
    /* min-width: 250px;
    max-width: 483px; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    flex: 1 0 0;
    margin: 10px 0 20px 0;
}

.frame-135-text-1 {
    align-self: center;
    font-family: Urbanist;
    color: #FFFFFF;
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: 72px;
    /* 112.5% */

}

.frame-135-text-2 {
    align-self: center;
    color: var(--color-text-read, #E2E8F0);
    font-family: Urbanist;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    /* 140% */
}

.frame-135-text-3 {
    align-self: center;
    font-family: Urbanist;
    color: #FFFFFF;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 72px;
    /* 112.5% */

}

.frame-135-button {
    display: flex;
    padding: 10px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #000;
    border-radius: 8px;
    background: var(--color-text-brand, #dee2e6);
    text-decoration: none;
}

.btn-get-started-text {
    font-family: Urbanist;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
}

.frame-135-button:hover {
    cursor: pointer;
    background-color: #FF971D;
    color: #fff;
}

.onboard {
    display: flex;
    justify-content: center;
    gap: 30px;
    max-width: 500px;
    width: 100%;
}

.input-field {
    display: flex;
    max-width: 300px;
    width: 100%;
}

.disableBackground {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    max-width: 100%;
    align-items: flex-start;
    justify-content: center;
    background-repeat: no-repeat;
    background-position: top center;
    border-bottom: 1px solid var(--back-ground-bg-divider, #475569);
}

@media screen and (max-width: 1440px) {
    .header {
        padding: 0 16px;
    }

    .mockup-2 {
        width: 400px;
    }

    .mockup-2 img {
        max-width: 400px;
        width: 100%;
    }

    .disableBackground {
        padding: 0 16px;
    }
}

@media screen and (max-width: 768px) {

    .header {
        padding: 0 16px;
    }

    .mockup-2 {
        width: auto;
        height: auto;
    }

    .onboard {
        gap: 15px;
        flex-direction: column;
        align-items: center;
        position: relative;
        display: flex;
    }

    .mockup-2 img {
        max-width: 400px;
        width: 100%;
    }

    .frame-191 {
        padding: 48px 0;
    }

    .frame-136 {
        gap: 33px;
        flex-direction: column;
    }

    .mockup-image {
        width: 100%;
        align-items: end;
    }

    .frame-135-text-1 {
        font-size: 40px;
        line-height: 36px;
    }

    .frame-135 {
        gap: 30px;
    }

    .frame-135-text-2 {
        text-align: center;
    }

    .frame-135-text-3 {
        font-size: 36px;
        line-height: 50px;
        text-align: center;
    }

    .frame-135-button {
        justify-content: center;
        display: flex;
        position: relative;
    }

}
</style>
