import api from './api'
export default {
    auth() {
        return api().get('auth');
    },
    login(params) {
        return api().post('user/sign_in', params)
    },
    loginTelegram(params) {
        return api().post('user/login_telegram', params)
    },
    getLatestWithdraw(params) {
        return api().get('statistics/latest_withdraw', {params})
    },
    getLatestDeposit(params) {
        return api().get('statistics/latest_deposit', {params})
    }
}