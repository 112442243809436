<template>
    <div class="privacy-policy">
        <div class="container">
            <div class="frame-title">
                <div class="title">Privacy Policy</div>
            </div>
            <div class="frame-content">
                <div class="wrapper-text">
                    <p> ("us", "we", or "our") operates the website (the "Service").
                        This page informs you of our policies regarding the collection, use and disclosure of Personal
                        Information when you use our Service.
                        We will not use or share your information with anyone except as described in this Privacy
                        Policy.
                        We use your Personal Information for providing and improving the Service. By using the Service,
                        you agree to the collection and use of information in accordance with this policy. Unless
                        otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same
                        meanings as in our terms and conditions.
                    </p>
                </div>
                <div class="title-1">1. PERSONAL INFORMATION WE COLLECT</div>
                <div class="wrapper-text">
                    <p>When you visit the Site, we automatically collect certain information about your device,
                        including information about your web browser, IP address, time zone, and some of the cookies
                        that are installed on your device. Additionally, as you browse the Site, we collect information
                        about the individual web pages or products that you view, what websites or search terms referred
                        you to the Site, and information about how you interact with the Site. We refer to this
                        automatically-collected information as “Device Information”. </p>
                    <p>We collect Device Information using the following technologies: </p>
                    <ul>
                        <li>“Cookies” are data files that are placed on your device or computer and often include an
                            anonymous unique identifier. For more information about cookies, and how to disable cookies,
                            visit.
                        </li>
                        <li>“Log files” track actions occurring on the Site, and collect data including your IP address,
                            browser type, Internet service provider, referring/exit pages, and date/time stamps.
                        </li>
                        <li>“Web beacons”, “tags”, and “pixels” are electronic files used to record information about
                            how you browse the Site.
                        </li>
                    </ul>
                    <p>When we talk about “Personal Information” in this Privacy Policy, we are talking both about
                        Device Information and Order Information.
                    </p>
                </div>
                <div class="title-1">2. Service Providers?</div>
                <div class="wrapper-text">
                    <p>We may employ third party companies and individuals to facilitate our Service, to provide the
                        Service on our behalf, to perform Service-related services or to assist us in analyzing how our
                        Service is used.</p>

                </div>
                <div class="title-1">3. Compliance with Laws</div>
                <div class="wrapper-text">
                    <p>We will disclose your Personal Information where required to do so by law or subpoena or if we
                        believe that such action is necessary to comply with the law and the reasonable requests of law
                        enforcement or to protect the security or integrity of our Service. </p>
                </div>
                <div class="title-1">4. Security
                </div>
                <div class="wrapper-text">
                    <p>The security of your Personal Information is important to us, but remember that no method of
                        transmission over the Internet, or method of electronic storage is 100% secure. While we strive
                        to use commercially acceptable means to protect your Personal Information, we cannot guarantee
                        its absolute security.
                    </p>
                </div>
                <div class="title-1">5. Links to Other Sites</div>
                <div class="wrapper-text">
                    <p>
                        Our Service may contain links to other sites that are not operated by us. If you click on a
                        third party link, you will be directed to that third party’s site. We strongly advise you to
                        review the Privacy Policy of every site you visit.
                    </p>
                    <p>We have no control over, and assume no responsibility for the content, privacy policies or
                        practices of any third party sites or services.</p>
                </div>
                <div class="title-1">6. Children’s Privacy</div>
                <div class="wrapper-text">
                    <p>Our Service does not address anyone under the age of 13 (“Children”).</p>
                    <p>We do not knowingly collect personally identifiable information from children under 13. If you
                        are a parent or guardian and you are aware that your Children has provided us with Personal
                        Information, please contact us. If we discover that a Children under 13 has provided us with
                        Personal Information, we will delete such information from our servers immediately.
                    </p>
                </div>
                <div class="title-1">7. Changes to this Privacy Policy</div>
                <div class="wrapper-text">
                    <p>
                        We may update our Privacy Policy from time to time. We will notify you of any changes by posting
                        the new Privacy Policy on this page.
                    </p>
                    <p>
                        You are advised to review this Privacy Policy periodically for any changes. Changes to this
                        Privacy Policy are effective when they are posted on this page.
                    </p>
                </div>
                <div class="title-1">8. Deleting Your Account</div>
                <div class="wrapper-text">
                    <p>
                        For security reasons, You’ll need to be able to log into your account or open a Support Ticket to request deletion. When you delete your account, your account and all your information will be permanently deleted, and you won't be able to retrieve your information. Go to Close your account plsease contact us.
                    </p>
                </div>
                <div class="title-1">9. Your acceptance of these terms</div>
                <div class="wrapper-text">
                    <p>
                        By using this Site, you signify your acceptance of this policy. If you do not agree to this
                        policy,
                        please do not use our Site. Your continued use of the Site following the posting of changes to
                        this
                        policy will be deemed your acceptance of those changes.
                    </p>
                </div>
                <div class="title-1">10. Contact Us</div>
                <div class="wrapper-text">
                    <p>
                        If you have any questions, concerns, or requests regarding your personal information or this
                        privacy
                        policy, please contact us at <a
                            href="mailto:miningplan.helpcenter@gmail.com"><span>miningplan.helpcenter@gmail.com</span></a>.
                    </p>

                </div>
                <div class="wrapper-text">Effective October 1, 2023</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PrivacyPolicy"
}
</script>

<style scoped>
.privacy-policy {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    max-width: 1200px;
    width: 100%;
    margin-top: 50px;
    margin-bottom: 30px;
    font-family: Urbanist;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.frame-title {
    display: flex;
    max-width: 789px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.title {
    color: var(--color-text-primary, #F1F5F9);
    text-align: center;
    font-family: Urbanist;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    align-self: stretch;
}

.effective-date {
    color: var(--color-text-read, #E2E8F0);
    font-family: Urbanist;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    /* 140% */
}

.frame-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

}

.wrapper-text {
    align-self: stretch;
    color: var(--color-text-read, #E2E8F0);
    font-family: Urbanist;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
}

.title-1 {
    color: var(--color-text-primary, #F1F5F9);
    font-family: Urbanist;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    /* 133.333% */
    align-self: stretch;
}


@media screen and (max-width: 1440px) {
    .privacy-policy {
        padding: 0 16px;
        margin-top: 20px;
    }

    .title {
        font-size: 30px;
    }

    .title-1 {
        font-size: 20px;
    }

    .wrapper-text {
        font-size: 16px;
    }
}
</style>