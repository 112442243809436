<template>
    <div class="frame-187">
        <div class="frame-172">
            <div class="frame-144">
                <div class="frame-144-text-1">Transaction Logs</div>
                <div class="frame-144-text-2">Our Latest Deposits And Withdrawals</div>
            </div>

        </div>
        <div class="frame-145">

            <div ref="table-responsive-ref" class="table-responsive">
                <div class="text-title"> Deposits</div>
                <table>
                    <thead>
                    <tr>
                        <th>Address</th>
                        <th>Amount</th>
                        <th>Transaction ID</th>
                        <th>Time</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for=" item in listLatestDeposits" :key="item.id">
                        <td>{{ item.address }}</td>
                        <td>{{ item.amount }} BNB</td>
                        <td>{{ item.tx_id }}</td>
                        <td>{{ item.time }}</td>
                    </tr>

                    </tbody>
                </table>
            </div>


            <div class="table-responsive">
                <span class="text-title"> Withdrawals</span>
                <table>
                    <thead>
                    <tr>
                        <th>Address</th>
                        <th>Amount</th>
                        <th>Transaction ID</th>
                        <th>Time</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for=" item in listLatestWithdraw" :key="item.id">
                        <td>{{ item.address }}</td>
                        <td>{{ item.amount }} BNB</td>
                        <td>{{ item.tx_id }}</td>
                        <td>{{ item.time }}</td>
                    </tr>

                    </tbody>
                </table>

            </div>
        </div>
    </div>

</template>
<script>
import Service from "../services/service";
import store from "@/stores/store";

export default {
    name: "Transaction",
    data() {
        return {
            page: 1,
            limit: 50,
            listLatestDeposits: [],
            listLatestWithdraw: [],
            interval: null,

        };
    },
    async created() {
        await this.GetLatestDeposit();
        await this.GetLatestWithdraw();
        this.interval = setInterval(this.ExtractDataStatistics, 1200)
    },
    beforeDestroy() {
        clearInterval(this.interval)
    },
    methods: {
        async GetLatestDeposit() {
            const params = {
                limit: this.limit,
                page: this.page,
                offset: this.offset
            }
            const response = await Service.getLatestDeposit(params).catch(() => {
            })
            if (response === undefined) {
                this.$toast.error("Something went wrong. Please try again!")
                return
            }
            if (response.data.status === false) {
                this.$toast.error(response.data.message);
                return
            }
            await store.dispatch("setListLatestDeposit", response.data.data.result);
            return
        },
        async GetLatestWithdraw() {
            const params = {
                limit: this.limit,
                page: this.page,
                offset: this.offset
            }
            const response = await Service.getLatestWithdraw(params).catch(() => {
            })
            if (response === undefined) {
                this.$toast.error("Something went wrong. Please try again!")
                return
            }

            if (response.data.status === false) {
                this.$toast.error(response.data.message);
                return
            }
            await store.dispatch("setListLatestWithdraw", response.data.data.result);
            return
        },

        async ExtractDataStatistics() {
            this.listLatestDeposits = []
            this.listLatestWithdraw = []
            const listLatestDepositStore = await store.getters.listLatestDeposit

            const listLatestWithdrawStore = await store.getters.listLatestWithdraw

            if (!listLatestDepositStore || !listLatestWithdrawStore.length) {
                return
            }
            for (let i = 0; i < 7; i++) {
                const randomIndex = Math.floor(Math.random() * listLatestWithdrawStore.length)
                this.listLatestDeposits.push(listLatestDepositStore[randomIndex])
                this.listLatestWithdraw.push(listLatestWithdrawStore[randomIndex])
            }
        }
    },
}
</script>

<style scoped>
.frame-187 {
    display: flex;
    padding: 80px 0;
    width: 100%;
    max-width: 1200px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    border-top: 1px solid var(--back-ground-bg-secondary, #1e293b);
}

.frame-172 {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin: 0 auto;
}

.frame-144 {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.frame-144-text-1 {
    color: var(--color-text-primary, #F1F5F9);
    text-align: center;
    font-family: Urbanist;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    padding-bottom: 5px;
    /* 100% */
    background: linear-gradient(90deg, #DBE6DE 2.54%, #F9B721 113.29%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    align-self: stretch;
}

.frame-144-text-2 {
    color: var(--color-text-secondary, #94A3B8);
    text-align: center;
    font-family: Urbanist;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    /* 140% */
    align-self: stretch;
}

.frame-145 {
    display: flex;
    position: relative;
    max-width: 1200px;
    width: 100%;
    gap: 20px;
    justify-content: center;
    align-items: flex-start;
}

.table-responsive {
    position: relative;
    display: flex;
    max-width: 1200px;
    width: 100%;
    flex-direction: column
}

.frame-146 {
    position: relative;
    display: flex;
    flex-direction: column;
}

.text-title {
    color: var(--color-text-primary, #F1F5F9);
    font-family: Urbanist;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    margin-top: 10px;
    margin-bottom: 10px;
}

table {
    position: relative;
    border-collapse: collapse;
    border: 1px solid #818183;
    width: 100%;
}

table th {
    text-transform: initial;
    text-align: center;
    color: var(--color-text-primary, #F1F5F9);
    font-family: Urbanist;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    cursor: pointer;
    padding: 8px;
    min-width: 30px;
    border: 1px solid #818183;
    background-color: #202022;
}

table td {
    text-align: center;
    padding: 8px;
    color: #F1F5F9;
}

table td:last-child {
    border-right: none;
}

table tbody tr:nth-child(2n) td {
    background: #323234;
}

@media screen and (max-width: 768px) {
    .frame-187 {
        padding: 40px 16px;
    }

    .frame-145 {
        flex-direction: column;
        align-items: center;
        margin-right: 10px;
    }

    .frame-170 {
        height: 80px;
    }

    .frame-170 svg {
        transform: rotate(90deg);
    }

    .card-features {
        flex-direction: row;
    }

    .frame-144-text-1 {
        font-size: 30px;
    }
}
</style>