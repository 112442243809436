<template>
    <div class="privacy-policy">
        <div class="container">
            <div class="frame-title">
                <div class="title">Contact</div>
            </div>
            <div class="frame-content">
                <div class="wrapper-text">
                    <p>
                        If you have any questions, concerns, or requests regarding your personal information or this
                        privacy
                        policy, please contact us at <b>miningplan.helpcenter@gmail.com</b>
                    </p>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Contact"
}
</script>

<style scoped>
.privacy-policy {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    max-width: 1200px;
    width: 100%;
    margin-top: 50px;
    margin-bottom: 30px;
    font-family: Urbanist;
    height: 100vh;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.frame-title {
    display: flex;
    max-width: 789px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.title {
    color: var(--color-text-primary, #F1F5F9);
    text-align: center;
    font-family: Urbanist;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    align-self: stretch;
}

.effective-date {
    color: var(--color-text-read, #E2E8F0);
    font-family: Urbanist;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    /* 140% */
}

.frame-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

}

.wrapper-text {
    align-self: stretch;
    color: var(--color-text-read, #E2E8F0);
    font-family: Urbanist;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    text-align: center;
}

.title-1 {
    color: var(--color-text-primary, #F1F5F9);
    font-family: Urbanist;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    /* 133.333% */
    align-self: stretch;
}


@media screen and (max-width: 1440px) {
    .privacy-policy {
        padding: 0 16px;
        margin-top: 20px;
    }

    .title {
        font-size: 30px;
    }

    .title-1 {
        font-size: 20px;
    }

    .wrapper-text {
        font-size: 16px;
    }
}
</style>