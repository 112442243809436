import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters.js'
import mutations from './mutations'
import actions from './actions'
// import Cookie from "vue-cookie";

Vue.use(Vuex);
export default new Vuex.Store({
    state: {
        isLogged: null,
        userData: null,
        listLatestDeposits: [],
        listLatestWithdraw: []
    },
    getters,
    mutations,
    actions
});