<template>
    <div class="privacy-policy">
        <div class="container">
            <div class="frame-title">
                <div class="title">Term Of Service</div>
            </div>
            <div class="frame-content">
                <div class="wrapper-text">
                    <p>This agreement is made between you as a user and platform Mining Plan.</p>
                    <p>Using the services of the site Mining Plan, you agree that you have read and accepted all the
                        conditions described in this agreement, as well as familiarized with our Privacy Policy.</p>
                    <p>If you do not agree with this agreement, immediately stop using the services of the platform
                        Mining Plan.</p>
                    <p>By connect your wallet at Mining Plan, you consent to the terms and conditions set forth in this
                        User Agreement.</p>
                    <p>If you have any related questions, you can always contact our support team.</p>

                </div>
                <div class="title-1">1. Terms and Definitions</div>
                <div class="wrapper-text">
                    <ul>
                        <li>Agreement is a list of the conditions of Users that must be accepted to continue working on
                            the platform Mining Plan.
                        </li>
                        <li>Cryptocurrency - a peer-to-peer electronic currency that does not have a single centralized
                            issuer, which is distributed between holders of such a currency.
                        </li>
                        <li>A personal account is a page inside the platform that is created as a result of registration
                            and acceptance of the User Agreement.
                        </li>
                        <li>Privacy policy - these are the rules for the collection, storage and use of personal data of
                            users.
                        </li>
                        <li>User is a natural or legal person registered in accordance with local national legislation,
                            registered and accepted the User Agreement.
                        </li>
                        <li>Deposit and withdrawal of funds - an operation to transfer the user's funds from a personal
                            account.

                        </li>
                        <li>Account data - personal data of the user, this is the wallet address that the user specified
                            when registering on the platform Mining Plan.
                        </li>
                    </ul>
                </div>
                <div class="title-1">2. Terms of agreement</div>
                <div class="wrapper-text">
                    <p>About such changes Mining Plan will notify all users by the specified mail by the user, and all
                        updates will be displayed in the Agreement Terms themselves.</p>
                    <p>If you continue to work on the platform, then you automatically accept these updates.
                    </p>
                    <p>By creating an account in Mining Plan, you confirm that you are at least 18 years old and you are
                        an individual or legal entity, an organization that is fully authorized to accept this User
                        Agreement between you and Mining Plan.</p>
                </div>
                <div class="title-1">3. Prohibition of use</div>
                <div class="wrapper-text">
                    <p>Using the services of the platform Mining Plan, you declare that you are not on any list of trade
                        or economic sanctions, such as the List of Sanctions of the UN Security Council and the like.

                    </p>
                    <p>This Agreement cannot be excluded under the laws of the country in which the user is located.</p>
                </div>
                <div class="title-1">4. Description of the services</div>
                <div class="wrapper-text">
                    <p>Users have the opportunity to register, open accounts and deposit assets in Mining Plan. Platform
                        users have the right to request the withdrawal of their funds, taking into account the
                        restrictions specified in the Terms of the agreement, if any.

                    </p>
                    <p>All users should be aware of all the risks associated with all possible movements of
                        cryptocurrency rates.

                    </p>
                    <p>Mining Plan never requires transfers of funds to the wallet and never asks for your wallet
                        private key.</p>
                    <p>Users need to be careful about promotions and any discounts that could lead to fraud and loss of
                        funds. All notifications that come to the e-mail specified by users will never require any
                        transaction data, personal data until a user requests it.
                    </p>
                    <p>The list of fraudulent transactions is wider, the user agrees that Mining Plan does not take
                        responsibility for any loss of funds resulting from the situations listed above.
                    </p>
                    <p>By using the services of Mining Plan, you confirm that all information provided by Mining Plan
                        under this Agreement is true, complete and accurate.
                    </p>
                </div>
                <div class="title-1">5. Registration and account requirements</div>
                <div class="wrapper-text">
                    <p>Before starting work on the platform Mining Plan all users must go through a simple registration.

                    </p>
                    <p>And also users need to accept the Terms of Service, Privacy Policy.</p>
                    <p>To create an account you need to provide your wallet address</p>
                    <p>At our sole discretion and depending on a number of conditions, Mining Plan may refuse to open an
                        account.
                    </p>
                </div>
                <div class="title-1">6. Account Requirements</div>
                <div class="wrapper-text">
                    <p>Mining Plan has the right to freeze or cancel accounts used by third parties that are not account
                        holders.</p>
                    <p>Mining Plan is not responsible for these accounts..</p>
                </div>
                <div class="title-1">7. Account security</div>
                <div class="wrapper-text">
                    <p>Mining Plan, strives to create maximum security to preserve user funds.</p>
                    <p>You are solely responsible for maintaining the security of your account at Mining Plan, for
                        maintaining the password of your wallet or private key, and the electronic login address.</p>
                    <p>Mining Plan does not take responsibility for any losses and consequences caused by your neglect
                        of the above account security statement.

                    </p>
                </div>
                <div class="title-1">8. Termination</div>
                <div class="wrapper-text">
                    <p>
                        You agree that Mining Plan has the right to stop your use of the platform, block funds if it is
                        suspected that such accounts have violated the Terms of the Agreement, the Privacy Policy, or
                        other applicable legislation. Mining Plan, has the right to save, use and provide transaction
                        data of violators at the request of the legislature.
                    </p>
                    <p>The termination of the agreement is possible with such factors:</p>
                    <ul>
                        <li>By order of a court or other regulatory authority.</li>
                        <li>If unauthorized access to the account is detected.</li>
                        <li>If non-natural account activity is detected.</li>
                    </ul>
                    <p>After the account is closed and the funds are blocked, the entire balance of the user will go to
                        pay off all costs and expenses Mining Plan.</p>

                </div>

                <div class="title-1">9. Privacy policy</div>
                <div class="wrapper-text">
                    <p>
                        Privacy policy is an important clause of this agreement. Mining Plan may, if necessary, amend
                        this clause of the agreement. All changes to the Privacy Policy, and any other clause, will be
                        notified to all users through the mailing list indicated by their email.
                    </p>

                </div>
                <div class="title-1">10. Compensation and Disclaimer</div>
                <div class="wrapper-text">
                    <p>
                        By accepting this agreement, the user agrees to reimburse all losses and expenses incurred Easy
                        Mining if the user's actions led to such losses.
                    </p>
                    <p>
                        Mining Plan is not responsible for errors and inaccuracies associated with the use of its
                        services.
                    </p>

                </div>
                <div class="title-1">9. Support service</div>
                <div class="wrapper-text">
                    <p>
                        If you have any questions, concerns, or requests regarding your personal information or this
                        privacy
                        policy, please contact us at <a
                            href="mailto:stakeb.helpcenter@gmail.com"><span>stakeb.helpcenter@gmail.com</span></a>.
                    </p>
                </div>
                <div class="wrapper-text">Effective October 1, 2023</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "TermOfService"
}
</script>

<style scoped>
.privacy-policy {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    max-width: 1200px;
    width: 100%;
    margin-top: 50px;
    margin-bottom: 30px;
    font-family: Urbanist;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.frame-title {
    display: flex;
    max-width: 789px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.title {
    color: var(--color-text-primary, #F1F5F9);
    text-align: center;
    font-family: Urbanist;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    align-self: stretch;
}

.effective-date {
    color: var(--color-text-read, #E2E8F0);
    font-family: Urbanist;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    /* 140% */
}

.frame-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

}

.wrapper-text {
    align-self: stretch;
    color: var(--color-text-read, #E2E8F0);
    font-family: Urbanist;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
}

.title-1 {
    color: var(--color-text-primary, #F1F5F9);
    font-family: Urbanist;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    /* 133.333% */
    align-self: stretch;
}


@media screen and (max-width: 1440px) {
    .privacy-policy {
        padding: 0 16px;
        margin-top: 20px;
    }

    .title {
        font-size: 30px;
    }

    .title-1 {
        font-size: 20px;
    }

    .wrapper-text {
        font-size: 16px;
    }
}
</style>