<template>
    <div class="main-container">
        <div class="landing-page">
            <div style="width: 100%;display: flex;justify-content: center;background-color: #202022;">
                <HeaderSection class="header-section"/>
            </div>
            <router-view></router-view>
           <FooterSection></FooterSection>

        </div>
    </div>
</template>
<script>
import HeaderSection from "@/components/HeaderSection.vue";
import FooterSection from "@/components/FooterSection.vue";

export default {
    name: "App",
    components: {
        FooterSection,
        HeaderSection,
    },
}
</script>
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;1,100;1,300;1,400&family=Urbanist:ital,wght@0,500;0,700;1,500;1,600&display=swap');

<style scoped>
 body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}



.main-container {
    background: var(--back-ground-bg-default, #19191A);
    position: relative;
}

.landing-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    z-index: 9999;
    position: relative;
}
</style>