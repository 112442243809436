<template>
    <div class="frame-187">
        <div class="frame-172">
            <div class="frame-144">
                <div class="frame-144-text-1">How MiningPlan Work?</div>
                <div class="frame-144-text-2">Learn about our work process. You need to follow the steps below to start your first mining.</div>
            </div>
            <div class="frame-145">
                <div class="card-features">
                    <div class="frame-169">
                        <RegisterIcon class="icon-feature"></RegisterIcon>
                        <div class="ellipse-4"></div>
                    </div>
                    <div class="frame-139">
                        <div class="frame-139-text-1">Register</div>
                        <div class="frame-139-text-2">Input your wallet address to register account on our service.</div>
                    </div>
                </div>
                <div class="frame-170">
                    <ChevronsRight />
                </div>
                <div class="card-features">
                    <div class="frame-169">
                        <ChoosePlanIcon class="icon-feature"></ChoosePlanIcon>
                        <div class="ellipse-4"></div>
                    </div>
                    <div class="frame-139">
                        <div class="frame-139-text-1">Choose Plan</div>
                        <div class="frame-139-text-2">Top up your balance and buy the Bots plan at the most reasonable price.
                        </div>
                    </div>
                </div>
                <div class="frame-170">
                    <ChevronsRight />
                </div>
                <div class="card-features">
                    <div class="frame-169">
                        <EarningIcon class="icon-feature"></EarningIcon>
                        <div class="ellipse-4"></div>
                    </div>
                    <div class="frame-139">
                        <div class="frame-139-text-1">Start Earning</div>
                        <div class="frame-139-text-2">Increase the mining power on the fly for all the coins using MiningPlan.
                        </div>
                    </div>
                </div>
                <div class="frame-170">
                    <ChevronsRight />
                </div>
                <div class="card-features">
                    <div class="frame-169">
                        <WithdrawIcon class="icon-feature"></WithdrawIcon>
                        <div class="ellipse-4"></div>
                    </div>
                    <div class="frame-139">
                        <div class="frame-139-text-1">Withdraw Profits</div>
                        <div class="frame-139-text-2">You will periodically receive mining output in your designated wallet</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ChevronsRight from "../assets/svg/chevrons-right.svg";
import RegisterIcon from "../assets/svg/register-icon.svg";
import ChoosePlanIcon from "../assets/svg/choose-icon.svg";
import EarningIcon from "../assets/svg/earning-icon.svg";
import WithdrawIcon from "../assets/svg/withdraw-icon.svg";

export default {
    name: "HowWork",
    components: {
        ChevronsRight,
        RegisterIcon,
        ChoosePlanIcon,
        EarningIcon,
        WithdrawIcon

    },
}
</script>

<style scoped>
.frame-187 {
    display: flex;
    padding: 112px 0;
    width: 100%;
    max-width: 1200px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    /*border-top: 1px solid var(--back-ground-bg-secondary,#1e293b);*/
}

.frame-172 {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    margin: 0 auto;
}

.frame-144 {
    display: flex;
    max-width: 789px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.frame-144-text-1 {
    color: var(--color-text-primary, #F1F5F9);
    text-align: center;
    font-family: Urbanist;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    padding-bottom: 5px;
    /* 100% */
    background: linear-gradient(90deg, #DBE6DE 2.54%, #F9B721 113.29%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    align-self: stretch;
}

.frame-144-text-2 {
    color: var(--color-text-secondary, #94A3B8);
    text-align: center;
    font-family: Urbanist;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    /* 140% */
    align-self: stretch;
}

.frame-145 {
    display: flex;
    position: relative;
    max-width: 1200px;
    align-items: flex-start;
}

.card-features {
    display: flex;
    padding: 24px;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 16px;
    border: 1px solid transparent;
    background: var(--back-ground-bg-default, #202022);
    box-shadow: 0 1rem 2rem 0 rgba(0, 0, 0, 0.25);
}

.card-features:hover {
    border-radius: 16px;
    border: 1px solid var(--back-ground-bg-accent, #f16a1b);
    background: var(--back-ground-bg-default, #1A191);
}

.frame-169 {
    display: flex;
    padding: 16px;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 8px;
    border-radius: 80px;
}

.frame-139 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}

.frame-139-text-1 {
    color: var(--color-text-primary, #F1F5F9);

    /* h3 */
    font-family: Urbanist;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    /* 133.333% */
    align-self: stretch;
}

.frame-139-text-2 {
    color: var(--color-text-read, #E2E8F0);
    align-self: stretch;
    /* lead-18 */
    font-family: Urbanist;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    /* 155.556% */
}

.frame-169 {
    background: var(--back-ground-bg-amber-op, rgba(249, 188, 33, 0.12));
    border-radius: 80px;
    padding: 16px;
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: flex-end;
    justify-content: flex-end;
    flex-shrink: 0;
    position: relative;
}

.icon-feature {
    flex-shrink: 0;
    position: relative;
    overflow: visible;
}

.ellipse-4 {
    background: var(--back-ground-bg-accent, #f9bc21);
    border-radius: 50%;
    flex-shrink: 0;
    width: 34px;
    height: 34.87px;
    position: absolute;
    left: calc(50% - 17px);
    top: calc(50% - 17px);
    filter: blur(20px);
}


.frame-170 {
    display: flex;
    height: 248px;
    padding: 0px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.frame-172-button {
    display: flex;
    padding: 10px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: var(--color-text-brand, #F9BC21);
    text-decoration: none;
}

.btn-open-account {
    color: #000;

    /* large */
    font-family: Urbanist;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    /* 155.556% */
}

@media screen and (max-width: 1440px) {
    .frame-187 {
        padding: 112px 16px;
    }
}

@media screen and (max-width: 768px) {
    .frame-187 {
        padding: 40px 16px;
    }

    .frame-145 {
        flex-direction: column;
        align-items: center;
    }

    .frame-170 {
        height: 80px;
    }

    .frame-170 svg {
        transform: rotate(90deg);
    }

    .card-features {
        flex-direction: row;
    }

    .frame-144-text-1 {
        font-size: 30px;
    }
}
</style>