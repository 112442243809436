<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>
<script>


export default {
    name: 'App',
    components: {}
}
</script>

<style>
body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}
* {
    scrollbar-width: thin;
    box-sizing: border-box;
}

::-webkit-scrollbar {
    height: 6px;
    width: 6px;
    background: transparent;

}
</style>
